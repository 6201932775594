<template>
    <div>

        <!--  轮播-->
        <myBannerCon></myBannerCon>

        <div class="main_box_bg ">
            <div class="clearfix mb20 w1200  mt20" style="height: 440px;">
                <div class="sales_volume_lf fl commonW mr20 bgff">
                    <div class="salesVolume_tit mb20">
                        <h2>销量TOP榜<span class="fr" @click="goTopBangDan">查看完整榜单 ></span></h2>
                    </div>
                    <div class="clearfix  salesVolume_main">
                        <div class=" salesVolume_lf ml20 mr20 movebig">
                            <router-link
                                :to="{path: 'details', query: {id:salesVolume.length != 0 ?salesVolume[0].goods_id:''}}"
                                target="_blank">
                                <img v-if="salesVolume[0]" :src="imgPath+salesVolume[0].default_image" alt=""
                                    class="max_img">
                                <i>01</i>
                                <h3>{{salesVolume.length != 0 ?salesVolume[0].goods_name:''}}</h3>
                                <strong>￥ {{salesVolume.length != 0 ? salesVolume[0].retail_price :''}}</strong>
                                <p v-if="isShowPrice" class="mt10">
                                    成本价:￥{{salesVolume.length != 0 ?salesVolume[0].gonghuojia:''}}</p>
                                <p @click.prevent="goLigon" v-if="!isShowPrice">登录后查看成本价</p>
                            </router-link>
                        </div>
                        <ul class=" salesVolume_rg mr20">
                            <li v-for="(item,index) in salesVolume" :key="index" class="pr clearfix" v-if="index>0">
                                <router-link :to="{path: 'details', query: {id:item.goods_id }}" target="_blank">
                                    <div class="fl movebig">
                                        <img :src="imgPath + item.default_image" alt="">
                                        <i>0{{index+1}}</i>
                                    </div>
                                    <div class="fl">
                                        <h3>{{item.goods_name}}</h3>
                                        <strong>￥ {{item.retail_price}}</strong>
                                        <p v-if="isShowPrice" class="mt10">成本价:￥{{item.gonghuojia}}</p>
                                        <p @click.prevent="goLigon" v-if="!isShowPrice">登录后查看成本价</p>
                                    </div>
                                </router-link>
                            </li>

                        </ul>
                    </div>

                </div>
                <div class="commonW fr bgff">
                    <div class="salesVolume_tit mb20 brand_tit ">
                        <h2>品牌闪购<span class="fr" @click="goBrandList">查看全部大牌 ></span></h2>
                    </div>
                    <div class="brand_main ml20 mr20">
                        <div class="brand_swiper">
                            <swiper :options="swiperOption" class="swiper-container swiper-pagination1" ref="mySwiper">
                                <swiper-slide v-for="(item,index) in barndSList">
                                    <div class="new_good_con movebig">
                                        <router-link :to="{path:'/brandList',query:{brandId:item.goods_id,type:2}}">
                                            <img class="banner-img" :src="routerBack[index]" />
                                            <!-- <img class="banner-img" :src="imgPath+item.img_url"/> -->
                                        </router-link>
                                    </div>
                                </swiper-slide>
                            </swiper>
                        </div>
                        <ol class="clearfix">
                            <li class="mr20 movetop" v-for="item in barndXList ">
                                <img :src="imgPath+item.img_url" alt="">
                                <!-- <router-link  target="_blank" :to="{path: 'details', query: {id:item.goods_id }}">
                                   
                               </router-link> -->
                            </li>
                        </ol>
                    </div>
                </div>
            </div>


            <div class="nine w1160 bgff ">
                <div class="nine_tit">
                    <h2 class="fl"></h2>
                    <span class="fr" @click="goAreaNine">查看更多 ></span>
                </div>
                <ul class="clearfix">
                    <li class="mr20" v-for="item in nine">
                        <GoodsItem :comeStyle="comeStyle" :imgWidth="160+'px'" :goodsDetail="item"></GoodsItem>
                        <i></i>
                    </li>
                </ul>
            </div>

            <div class="clearfix w1200 mt20 newProduct">
                <div class="fl new_img pr">
                    <img src="../../assets/images/img_new_title.png" alt="">
                    <span @click="goNew">查看更多 ></span>
                </div>
                <mynewGoods></mynewGoods>
            </div>
            <div class="bgff mt20  w1200">
                <div class="salesVolume_tit mb20 buyer_tit">
                    <h2>买手推荐<span class="fr" @click="goBuyDan">查看完整榜单 ></span></h2>
                </div>
                <div class="clearfix buyer_box">
                    <div class="mr20 ml20 textLeft">
                        <GoodsItem class="leftImg" :imgWidth="460+'px'" :comeStyle="buyerStyle" :maxSize="18+'px'"
                            :priceSize="24 +'px'" :goodsDetail="buyer[0]"></GoodsItem>
                    </div>
                    <div class=" buyer_list">
                        <ul>
                            <li class="mr20" v-for="(item,index) in buyer" v-if="index > 0">
                                <GoodsItem :imgWidth="213+'px'" :comeStyle="buyerRgStyle" :goodsDetail="item">
                                </GoodsItem>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="optimization">
                <h2><i></i>为您优选<i></i></h2>
                <ul class="w1200  clearfix">
                    <!--<li v-for="item in optimization" class="bgff mr20 mb20"><GoodsItem :imgWidth="204+'px'" :comeStyle="comeStyle" :imgUrl="item.default_image" :goodsTit="item.goods_name" :goodsPrice="item.retail_price"></GoodsItem></li>-->
                    <li v-for="item in optimization" class="bgff mr20 mb20">
                        <GoodsItem :imgWidth="204+'px'" :comeStyle="comeStyle" :goodsDetail="item"></GoodsItem>
                    </li>

                </ul>
            </div>
            <div class="mt30 mb20">
                <el-pagination @current-change='handleCurrentChange' background :hide-on-single-page="total< limit"
                    :current-page.sync="page" :page-size="limit" layout="prev, pager, next" :total="total">
                </el-pagination>
            </div>
        </div>
        <!--   底部    -->
        <myFooter></myFooter>

    </div>
</template>

<script>

    import {GLdegioosstt, portalLogin, getBrandGoodsbrand} from '@/global/https'
    import {swiperSlide} from 'vue-awesome-swiper'
    import bannerCon from '@/views/homePage/bannerCon' //轮播
    import footer from '@/components/footer'
    import GoodsItem from '@/components/GoodsItem.vue'
    import icon from '@/components/icon/iconfont.vue' // 字体图标
    import newGoods from '@/views/homePage/newGoods.vue' // 新品首发
    import {getIndex, myBranner} from '@/views/homePage/home'  // 接口地址
    export default {
        components: {
            myFooter: footer, // 底部
            myBannerCon: bannerCon,//轮播
            mynewGoods: newGoods, // 新品首发
            GoodsItem,
            icon,
            swiperSlide// 品牌闪购
        },

        data() {
            return {
                routerBack: [
                    'https://7dd-statics.oss-cn-beijing.aliyuncs.com/data/files/recommend/20191212/5df2034b7bbc1.jpg',
                    'https://7dd-statics.oss-cn-beijing.aliyuncs.com/data/files/recommend/20191212/5df203afda562.jpg',
                    'https://7dd-statics.oss-cn-beijing.aliyuncs.com/data/files/recommend/20191212/5df203fcec9dd.jpg',
                    'https://7dd-statics.oss-cn-beijing.aliyuncs.com/data/files/recommend/20191212/5df20441b7dc0.jpg',
                    'https://7dd-statics.oss-cn-beijing.aliyuncs.com/data/files/recommend/20191212/5df2048f37893.jpg',
                    'https://7dd-statics.oss-cn-beijing.aliyuncs.com/data/files/recommend/20191212/5df204c14b722.jpg',
                ],
                isShowPrice: true, //是否显示成本价
                comeStyle: {
                    line: 1, //2 默认一行隐藏 2为2行隐藏
                    alignCent: 'center',
                },
                buyerStyle: {
                    line: 2,
                    hSize: '18px',
                    linHeight: '22px',
                    alignCent: 'left',
                    pMt: '20px',
                    allBorder: 1,//买手推荐 1 border 全部
                },
                buyerRgStyle: {
                    allBorder: 1,//买手推荐 1 border 全部
                },
                isShowLogin: false, // 是否显示登录弹框
                isShowTip: false, // 是否显示校验提示
                nine: [],//九九专区
                optimization: [],//为您优选
                salesVolume: [],//销量
                buyer: [],//买手推荐
                barndSList: [],//品牌闪购
                barndXList: [],//品牌闪购
                swiperOption: {// 品牌闪购
                    pagination: '.swiper-pagination1',
                    slidesPerView: 4.5,
                    loop: true,
                    spaceBetween: 30,
                    centeredSlides: false,
                    onSlideChangeEnd: swiper => {
                        //这个位置放swiper的回调方法
                        this.page = swiper.realIndex + 1;
                        this.index = swiper.realIndex;
                    }
                },
                total: 0,
                page: 1,
                limit: 20,
            }
        },
        //定义swiper对象
        computed: {
            // 品牌闪购
            swiper() {
                return this.$refs.mySwiper.swiper;
            }
        },
        mounted() {
            portalLogin({})

            // this.getGoodsList(1,1,6,1);
            this.getIndex(32, 6, 1, 1);//九九专区
            this.getIndex(31, 4, 4);//销量
            this.getIndex(34, 7, 6);//买手
            this.getGoodsList(0, 0, this.limit, this.page);//为您优选

            this.getPoster(12); //品牌山沟
            this.getPoster(13);// 品牌闪购商品
            // 是否显示成本价
            let cookie = JSON.parse(localStorage.getItem('cookie') || '{}')
            if (cookie.tokenId) {
                // 是否显示成本价
                this.isShowPrice = true
            } else {
                this.isShowPrice = false
            }
            this.swiper.slideTo(0, 0, false); // 品牌闪购
        },
        methods: {
            async getIndex(catId, limit, order, is_nine) {
                let {code, msg, total, rows, pageNum} = await GLdegioosstt({order: order, pageSize: limit, pageNum: 1, is_nine: is_nine});
                if (code = 200) {
                    switch (catId) {
                        case 32:
                            this.nine = rows; //jiu jiu 专区
                            break;
                        case 31: //销量
                            this.salesVolume = rows;
                            break;
                        case 34:
                            this.buyer = rows;//买手推荐
                    }
                }
            },
            async getGoodsList(order, isNine, limit, page) {
                GLdegioosstt({pageSize: limit, pageNum: page, order: '11'}).then(res => {
                    let {code, msg, total, rows, pageNum} = res
                    console.log(code, msg, limit, rows, pageNum, '========================consiole.log()')
                    if (res.code == 0) {
                        this.optimization = rows
                        this.total = total;
                    }
                })
                return false

            },
            async getBrand() {
                let {code, msg, data} = await getBrandGoodsbrand({pageSize: 5});
                if (code == 0) {
                    this.brandData = data;
                }
            },
            //     品牌闪购
            async getPoster(id) {
                if (id == 12) {
                    let {code, msg, data} = await getBrandGoodsbrand({});
                    if (code == 0) {
                        this.barndSList = data;
                    }
                } else {
                    let {message, code, data, status} = await myBranner({cat_id: id});
                    if (status == 200) {
                        switch (id) {
                            case 12:
                                this.barndSList = data; //品牌商品 上
                                break;
                            case 13:
                                this.barndXList = data; //品牌商品 下
                                break;
                        }
                    }
                }



            },

            // 跳转品牌闪够
            goBrandList() {
                this.$router.push({path: '/brand', query: {type: 2}})
            },
            // 跳转top榜单
            goTopBangDan() {
                this.$router.push({path: '/goodsList', query: {type: 4}})
            },
            // 买手推荐
            goBuyDan() {
                this.$router.push({path: '/goodsList', query: {type: 6}})
            },
            // 跳转99 专区
            goAreaNine() {
                this.$router.push({path: '/goodsList', query: {type: 1}})
            },
            handleCurrentChange(val) {

                this.page = val;
                this.getGoodsList(0, 0, this.limit, this.page);//买手推荐
            },
            goNew() {
                this.$router.push({path: '/goodsList', query: {type: 3}});
            },
            goLigon() { //显示登陆弹框
                this.$store.commit('changeShowLogin', 1);
            }
        },


    }
</script>
<style>
    .leftImg img {
        margin-top: 110px;
    }
</style>
<style lang="less" scoped>
    .textLeft .goodsItem div {
        border: 1px solid #ddd;
    }

    .salesVolume_rg li a {
        display: flex;
    }

    .newProduct {
        background: #fff;

        .new_img {
            width: 180px;
            height: 346px;

            span {
                width: auto;
                text-align: center;
                position: absolute;
                bottom: 30px;
                left: 57px;
                color: #FFFFFF;
                font-size: 16px;
                cursor: pointer;
            }
        }
    }

    .main_box_bg {
        background: #F5F5F5;
    }

    .w1160 {
        width: 1160px;
        margin: 0 auto;
    }

    .w1200 {
        width: 1200px;
        margin: 0 auto;
    }

    .bgff {
        background: #fff;
    }

    .salesVolume_tit {
        height: 66px;
        line-height: 66px;
        border-bottom: 1px solid #eee;

        h2 {
            padding-left: 54px;
            color: #333;
            font-size: 24px;
            font-weight: bold;
            text-align: left;
            background: url(../../assets/images/icon_top.png) 21px center no-repeat;

            span {
                color: #333333;
                font-size: 14px;
                margin-right: 16px;
                font-weight: 400;
                cursor: pointer;
            }

            span:hover {
                color: #EC1430
            }
        }
    }

    .salesVolume_tit.brand_tit h2 {
        padding-left: 55px;
        background: url(../../assets/images/icon_pinpai.png) 16px center no-repeat;

        span {
            cursor: pointer;
        }

        span:hover {
            color: #EC1430;
        }
    }

    .salesVolume_tit.buyer_tit h2 {
        background: url(../../assets/images/icon_recommend.png) 16px center no-repeat;
    }

    .salesVolume_main {
        padding-bottom: 16px;
        display: flex;

        i {
            position: absolute;
            left: 0;
            top: 0;
            background: url(../../assets/images/icon_top_bg.png) center center no-repeat;
            width: 36px;
            height: 24px;
            line-height: 24px;
            text-align: center;
            color: #fff;
        }

        img {
            width: 219px;
            height: 219px;
            display: block;
            border: 1px solid #ddd;
        }

        h3 {
            color: #333333;
            font-size: 16px;
            .lineClamp(2);
            margin-bottom: 16px;
            margin-top: 16px;
            line-height: 19px;
        }

        strong {
            color: #EC1430;
            font-size: 16px;
            height: 15px;
            font-weight: bold;
        }

        p {
            color: #EC9314;
            font-size: 14px;
            height: 14px;
            line-height: 14px;
            margin-top: 13px !important;
        }
    }

    .lineClamp(@line) {
        overflow: hidden;
        text-overflow: ellipsis;
        display: box;
        display: -webkit-box;
        line-clamp: @line;
        -webkit-line-clamp: @line;
        -webkit-box-orient: vertical;
    }

    .salesVolume_lf {
        text-align: left;
        width: 219px;
        font-size: 14px;
        position: relative;

    }

    .commonW {
        width: 590px;
    }

    .salesVolume_rg {
        li {
            text-align: left;
            margin-bottom: 16px;
            display: flex;

            div:first-child {
                width: 98px;
                height: 98px;
                margin-right: 16px;

            }

            img {
                width: 100px;
                height: 100px;
                display: inline-block;
                border: 1px solid #EEEEEE;
            }

            h3 {
                font-size: 14px;
                line-height: 18px;
                margin-top: 2px;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .brand_main {
        .brand_swiper {
            width: 549px;
            height: 100px;
            margin-bottom: 20px;

            img {
                width: 100px;
                height: 100px;
            }
        }

        ol {
            padding-bottom: 20px;

            li {
                width: 170px;
                height: 214px;
                /*border: 1px solid #ddd;*/
                float: left;

                img {
                    width: 170px;
                    height: 214px;
                }

                &:last-child {
                    margin-right: 0 !important;
                }
            }
        }

    }

    .buyer_box {
        display: flex;
    }

    .buyer_list li {
        float: left;
    }

    .nine {
        padding: 20px;

        .nine_tit {
            height: 100px;
            line-height: 100px;
            margin-bottom: 33px;
            background: url(../../assets/images/bg_nine_title.png) center center no-repeat;

            h2 {
                width: 178px;
                height: 100px;
                background: url(../../assets/images/img_nine_title.png) 23px center no-repeat;
            }

            span {
                cursor: pointer;
                margin-right: 33px;
                font-size: 16px;
                color: #FFFFFF;
            }
        }

        li {
            float: left;
            position: relative;
            padding-right: 20px;

            &:last-child {
                margin-right: 0 !important;
                padding-right: 0;
            }

            i {
                width: 1px;
                height: 270px;
                position: absolute;
                right: 0;
                top: 0;
                background: url(../../assets/images/img_line.png) 0 0 no-repeat;
            }
        }
    }

    .optimization {
        h2 {
            color: #333333;
            font-size: 30px;
            background: #F5F5F5;
            height: 94px;
            line-height: 94px;
            font-weight: bold;

            i {
                width: 112px;
                height: 10px;
                display: inline-block;
                background: url(../../assets/images/bg_title_optimize_l.png) center center no-repeat;

                &:first-child {
                    margin-right: 26px;
                }

                &:last-child {
                    margin-left: 30px;
                }
            }
        }

        li {
            float: left;
            width: 224px;
            padding-top: 10px;
            text-align: center;

            &:nth-child(5n) {
                margin-right: 0 !important;
            }
        }
    }

    /*   登录  */
    .login_bg {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .6);
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: 111;

        .login_box {
            width: 490px;
            height: 340px;
            background: #fff;
            position: fixed;
            top: 50%;
            left: 50%;
            margin-left: -245px;
            margin-top: -170px;
            border-radius: 10px;

            button {
                width: 180px;
                height: 60px;
                border: 1px solid rgba(203, 203, 203, 1);
                border-radius: 4px;
                color: #CBCBCB;
                font-size: 20px;
                background: none;
                margin-top: 40px;
                margin-left: 49px;
                cursor: pointer;
            }

            button.red {
                background: #E60026;
                color: #fff !important;
                margin-right: 49px;
                margin-left: 0;
                border: 1px solid #E60026;
            }

            .showtip {
                height: 32px;
                line-height: 32px;
                padding: 0 20px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background: rgba(0, 0, 0, .5);
                color: #fff;
                border-radius: 4px;
            }

            ul {
                width: 390px;
                margin: 0 auto;
                padding-top: 50px;

                li {
                    width: 388px;
                    height: 58px;
                    border: 1px solid #ddd;
                    border-radius: 4px;
                    margin-bottom: 20px;
                    float: left;

                    .btn {
                        float: left;
                        width: 58px;
                        height: 58px;
                        padding-right: 7px;
                        text-align: center;
                        line-height: 58px;
                    }

                    input {
                        width: 320px;
                        height: 58px;
                        float: left;
                        background: none;
                        font-size: 18px;
                    }
                }

            }
        }
    }
</style>